import React, { useState } from "react";
import _v from "validator";
import { Button, Card, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  ArrowLeftOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

//redux
import { handleEmail, handleCode } from "../../redux/auth/auth.actions";
import { stepRedux, statusRedux } from "../../redux/auth/auth.slice";

//styles
import "../../assets/styles/auth/recover-pass.css";

//images
import Logo from "../../assets/images/logo-inovyo.svg";

const RecoverPassPage = () => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    email: "",
    code: "",
  });
  const step = useSelector(stepRedux);
  const status = useSelector(statusRedux);

  const submitEmail = () => {
    let error = "";
    const { email } = inputValues;

    if (_v.isEmpty(email)) {
      error = "Email está vazio";
    } else if (!_v.isLength(email, { min: 6, max: 150 })) {
      error = "Email deve ter de 6 a 150 caracteres";
    } else if (email.indexOf("@") <= -1 || !_v.isEmail(email)) {
      error = "Email inválido";
    }

    if (error === "") {
      toast.loading("Verificando o e-mail", { toastId: "email" });
      dispatch(handleEmail(inputValues.email));
    } else {
      toast.dismiss("email");
      toast.warning(error);
    }
  };

  const submitCode = () => {
    let error = "";
    const { code } = inputValues;

    if (_v.isEmpty(code)) {
      error = "Código está vazio";
    } else if (!_v.isLength(code, { min: 6, max: 6 })) {
      error = "Código deve ter de 6 números";
    } else if (!/^[0-9]{6}$/g.test(code)) {
      error = "Código inválido";
    }

    if (error === "") {
      toast.loading("Verificando o código", { toastId: "codigo" });
      dispatch(handleCode(inputValues));
    } else {
      toast.dismiss("codigo");
      toast.warning(error);
    }
  };

  return (
    <div id="recover-pass-page">
      <Card>
        <Form labelCol={24} wrapperCol={24} layout="vertical">
          <Form.Item>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
          </Form.Item>
          <Form.Item
            label="Por favor, insira seu e-mail abaixo"
            name="email"
            rules={[
              { required: true, message: "Por favor, insira seu e-mail!" },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              value={inputValues.email}
              prefix={<UserOutlined />}
              name="email"
              onChange={({ target: { value } }) =>
                setInputValues({ ...inputValues, email: value })
              }
              autoComplete="on"
              placeholder="E-mail"
            />
          </Form.Item>

          {step === 2 && status === "success" && (
            <div>
              <Form.Item
                label="Por favor, insira o código de verificação"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira seu código de verificação!",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  prefix={<CheckCircleFilled />}
                  id="code"
                  name="code"
                  onChange={({ target: { value } }) =>
                    setInputValues({ ...inputValues, code: value })
                  }
                  autoComplete="on"
                  placeholder="Código de verificação"
                />
              </Form.Item>
            </div>
          )}

          {step === 1 || status === "" || status === "error" ? (
            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={() => submitEmail()}
              >
                REDEFINIR SENHA
              </Button>
            </Form.Item>
          ) : (
            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={() => submitCode()}
              >
                CONFIRMAR
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="link" onClick={() => window.history.back()}>
              <ArrowLeftOutlined /> Voltar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default RecoverPassPage;
