/**
 * Transforma dados é padrão base64
 * @param {*} data
 * @returns {string}
 */
export const base64encode = (data, isJson = false) => {
  if (isJson) {
    return window.btoa(JSON.stringify(data));
  }
  return window.btoa(data);
};

/**
 * Desfaz o processo de base64
 * @param {*} data
 * @returns {string}
 */
export const base64decode = (data, isJson = false) => {
  if (isJson) {
    return JSON.parse(window.atob(data));
  }
  return window.atob(data);
};
