import React, { useState, useEffect } from "react";
import { Card, Typography, Form, Col, Row, Input, Button, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

//redux
import { accountDetailsRX } from "../../redux/settings/settings.slice";
import {
  getDetails,
  updatePassword,
} from "../../redux/settings/settings.actions";

//components
import TabNavigation from "./components/TabNavigation";

const AccountPage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const [pw, setPw] = useState({ pass: "", repeatPass: "" });
  const [showModal, setShowModal] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const accountDetails = useSelector(accountDetailsRX);

  useEffect(() => {
    dispatch(getDetails());
    // eslint-disable-next-line
  }, []);

  const PassWordDetails = () => {
    const passSize = pw.pass.length >= 8 && pw.pass.length <= 64;
    const passLetters = pw.pass.replace(/[0-9]+|[\s]+|[!@#$%&*^]+/g, "");
    const passSymbols = pw.pass.replace(/[0-9A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+/g, "");
    const passHasSpace = /\s+/g.test(pw.pass);
    const passNumbers = pw.pass.replace(
      /[A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+|[!@#$%&*^]+/g,
      ""
    );
    const passEqual = pw.pass === pw.repeatPass;

    let check1 = passSize;
    let check2 = passLetters.length >= 4;
    let check3 = passNumbers.length >= 2;
    let check4 = passSymbols.length >= 1;
    let check5 = !passHasSpace && pw.pass.length > 0;
    let check6 = passEqual;

    return (
      <div className="pass-details">
        <p>
          {check1 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          Entre 8 e 64 caracteres
        </p>
        <p>
          {check2 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          4 ou mais letras
        </p>
        <p>
          {check3 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          2 ou mais números
        </p>
        <p>
          {check4 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          1 ou mais símbolos (!@#$%&*^)
        </p>
        <p>
          {check5 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          Sem espaço(s)
        </p>
        <p>
          {check1 && check6 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          As senhas coencidem
        </p>
      </div>
    );
  };

  const savePassword = () => {
    if (currentPass.length > 0) {
      dispatch(
        updatePassword(
          JSON.stringify({
            email: accountDetails?.email,
            password: currentPass,
            newpassword: pw.repeatPass,
          })
        )
      );
    } else {
      toast.error("Informe sua senha atual!");
    }
  };

  const analyzePassword = () => {
    const passSize = pw.pass.length >= 8 && pw.pass.length <= 64;
    const passLetters = pw.pass.replace(/[0-9]+|[\s]+|[!@#$%&*^]+/g, "");
    const passSymbols = pw.pass.replace(/[0-9A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+/g, "");
    const passHasSpace = /\s+/g.test(pw.pass);
    const passEqual = pw.pass === pw.repeatPass;
    const passNumbers = pw.pass.replace(
      /[A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+|[!@#$%&*^]+/g,
      ""
    );

    let check1 = passSize;
    let check2 = passLetters.length >= 4;
    let check3 = passNumbers.length >= 2;
    let check4 = passSymbols.length >= 1;
    let check5 = !passHasSpace && pw.pass.length > 0;
    let check6 = passEqual;

    if (check1 && check2 && check3 && check4 && check5 && check6) {
      setShowModal(!showModal);
    } else {
      toast.warning("A senha não atende os requisitos de segurança");
    }
  };

  return (
    <Card>
      <TabNavigation>
        <div id="account-page">
          {accountDetails !== null && (
            <>
              <Title level={4}>Informações da Conta</Title>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <strong>Empresa</strong>
                    <p style={{ color: "#a3a3a3" }}>{accountDetails.company}</p>
                  </Col>
                  <Col xs={24} sm={12}>
                    <strong>Email</strong>
                    <p style={{ color: "#a3a3a3" }}>{accountDetails.email}</p>
                  </Col>
                </Row>
                {!accountDetails.oauth_only && (
                  <div style={{ marginTop: 20 }}>
                    <Row>
                      <Col span={24}>
                        <Title level={5}>Alterar Senha</Title>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          label="Nova senha"
                          name="newpassword"
                          htmlFor="newpassword"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, insira uma nova senha!",
                            },
                          ]}
                          style={{ display: "inline-block", width: "100%" }}
                        >
                          <Input.Password
                            name="newpassword"
                            id="newpassword"
                            value={pw.pass}
                            onChange={({ target: { value } }) => {
                              setPw({ ...pw, pass: value });
                            }}
                          />
                        </Form.Item>
                        <PassWordDetails />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          label="Confirmar senha"
                          name="confirm-new-password"
                          htmlFor="confirm-new-password"
                          dependencies={["newpassword"]}
                          rules={[
                            {
                              required: true,
                              message:
                                "Por favor, repita a senha digitada anteriormente!",
                            },
                          ]}
                          style={{ display: "inline-block", width: "100%" }}
                        >
                          <Input.Password
                            name="confirm-new-password"
                            id="confirm-new-password"
                            value={pw.repeatPass}
                            onChange={({ target: { value } }) => {
                              setPw({ ...pw, repeatPass: value });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ float: "right" }}>
                      <Form.Item style={{ float: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={() => analyzePassword()}
                          disabled={pw.pass === "" || pw.repeatPass === ""}
                        >
                          Alterar senha
                        </Button>
                      </Form.Item>
                    </Row>
                  </div>
                )}
              </Form>
            </>
          )}
        </div>
      </TabNavigation>
      <Modal
        title="Confirmar alteração de senha"
        open={showModal}
        style={{ top: 20 }}
        onCancel={() => setShowModal(!showModal)}
        onOk={() => savePassword()}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Form.Item
          label="Senha atual"
          htmlFor="current-password"
          style={{ margin: "0 auto", display: "inline-block", width: "100%" }}
        >
          <Input.Password
            name="current-password"
            id="current-password"
            value={currentPass}
            onChange={({ target: { value } }) => setCurrentPass(value)}
          />
        </Form.Item>
      </Modal>
    </Card>
  );
};

export default AccountPage;
