import React, { useState, useEffect } from "react";
import { Button, Card, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  LockOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";

//redux
import { saveMyPass } from "../../redux/auth/auth.actions";

//styles
import "../../assets/styles/auth/new-pass.css";

//images
import Logo from "../../assets/images/logo-inovyo.svg";

//utils
import { getSession, deleteSession } from "../../utils/Session";
import { base64decode } from "../../utils/Crypt";

const NewPassPage = () => {
  const dispatch = useDispatch();
  const [pass, setPass] = useState("");
  const [permission, setPermission] = useState(true);

  useEffect(() => {
    const internalData = getSession("RECOVER");
    if (internalData !== "") {
      const datanewpass = base64decode(internalData, true);
      if (Object.entries(datanewpass).length <= 0) {
        setPermission(false);
        deleteSession("RECOVER");
      }
    } else {
      deleteSession("RECOVER");
      setPermission(false);
    }
  }, []);

  const PassWordDetails = () => {
    const passSize = pass.length >= 8 && pass.length <= 64;
    const passLetters = pass.replace(/[0-9]+|[\s]+|[!@#$%&*^]+/g, "");
    const passSymbols = pass.replace(/[0-9A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+/g, "");
    const passHasSpace = /\s+/g.test(pass);
    const passNumbers = pass.replace(
      /[A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+|[!@#$%&*^]+/g,
      ""
    );

    let check1 = passSize;
    let check2 = passLetters.length >= 4;
    let check3 = passNumbers.length >= 2;
    let check4 = passSymbols.length >= 1;
    let check5 = !passHasSpace && pass.length > 0;

    return (
      <div className="pass-details" style={{ marginTop: 10, zoom: 0.85 }}>
        <p>
          {check1 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          Entre 8 e 64 caracteres
        </p>
        <p>
          {check2 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          4 ou mais letras
        </p>
        <p>
          {check3 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          2 ou mais números
        </p>
        <p>
          {check4 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          1 ou mais símbolos (!@#$%&*^)
        </p>
        <p>
          {check5 ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          )}{" "}
          Sem espaço(s)
        </p>
      </div>
    );
  };

  const savePass = () => {
    toast.loading("Alterando senha...", { toastId: "pass" });

    const passSize = pass.length >= 8 && pass.length <= 64;
    const passLetters = pass.replace(/[0-9]+|[\s]+|[!@#$%&*^]+/g, "");
    const passSymbols = pass.replace(/[0-9A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+/g, "");
    const passHasSpace = /\s+/g.test(pass);
    const passNumbers = pass.replace(
      /[A-Za-zÀ-ÖØ-öø-ÿ]+|[\s]+|[!@#$%&*^]+/g,
      ""
    );

    let check1 = passSize;
    let check2 = passLetters.length >= 4;
    let check3 = passNumbers.length >= 2;
    let check4 = passSymbols.length >= 1;
    let check5 = !passHasSpace && pass.length > 0;

    if (check1 && check2 && check3 && check4 && check5) {
      const internalData = getSession("RECOVER");
      const datanewpass = base64decode(internalData, true);
      dispatch(saveMyPass({ ...datanewpass, password: pass }));
    } else {
      toast.dismiss("pass");
      toast.warning("A senha não atende os requisitos de segurança");
    }
  };

  return (
    <div id="new-pass-page">
      {permission && (
        <Card>
          <Form labelCol={24} wrapperCol={24} layout="vertical">
            <Form.Item>
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </Form.Item>
            <Form.Item
              label="Por favor, insira a nova senha"
              name="password"
              rules={[
                { required: true, message: "Por favor, insira sua senha!" },
              ]}
            >
              <Input.Password
                style={{ width: "100%" }}
                value={pass}
                prefix={<LockOutlined />}
                name="password"
                onChange={({ target: { value } }) => setPass(value)}
                placeholder="Senha"
              />
            </Form.Item>
            <Form.Item>
              <PassWordDetails />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={() => savePass()}
                disabled={pass === ""}
              >
                ALTERAR SENHA
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                <ArrowLeftOutlined /> Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
      {!permission && (
        <Card>
          <Form labelCol={24} wrapperCol={24} layout="vertical">
            <Form.Item>
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </Form.Item>
            <div className="no-permission">
              <p>401</p>
              <span>
                Sem permissão para <br /> acessar esta tela
              </span>
            </div>
            <Form.Item>
              <Button
                type="link"
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                <ArrowLeftOutlined /> Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default NewPassPage;
