import { useEffect, useContext, useState } from "react";
import { Interweave } from "interweave";
import {
  Card,
  Space,
  Select,
  Input,
  Divider,
  Empty,
  Menu,
  Popover,
  Modal,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AppstoreOutlined,
  MoreOutlined,
  EditOutlined,
  ReloadOutlined,
  SendOutlined,
  ExportOutlined,
} from "@ant-design/icons";

//components
import { Context } from "../../providers/AuthContext";

//redux
import {
  fetchHub,
  reloadApp,
  publishApp,
} from "../../redux/general/general.actions";
import {
  surveysRedux,
  qlikRedux,
  reloadRedux,
  setQlikRedux,
  setReloadRedux,
} from "../../redux/general/general.slice";
import { loadingRedux } from "../../redux/application/app.slice";

//styles
import "../../assets/styles/general/home.css";

//images
import axIcon from "../../assets/images/circle-ax.svg";
import axIconI from "../../assets/images/circle-ax-i.svg";
import bxIcon from "../../assets/images/circle-bx.svg";
import bxIconI from "../../assets/images/circle-bx-i.svg";
import cxIcon from "../../assets/images/circle-cx.svg";
import cxIconI from "../../assets/images/circle-cx-i.svg";
import cxcIcon from "../../assets/images/circle-cxc.svg";
import cxcIconI from "../../assets/images/circle-cxc-i.svg";
import exIcon from "../../assets/images/circle-ex.svg";
import exIconI from "../../assets/images/circle-ex-i.svg";
import slxIcon from "../../assets/images/circle-slx.svg";
import slxIconI from "../../assets/images/circle-slx-i.svg";
import ahIcon from "../../assets/images/circle-ah.svg";
import ahIconI from "../../assets/images/circle-ah-i.svg";

const HomePage = () => {
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  const surveys = useSelector(surveysRedux);
  const loading = useSelector(loadingRedux);
  const qlik = useSelector(qlikRedux);
  const reload = useSelector(reloadRedux);

  const [surveysClone, setSurveysClone] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [category, setCategory] = useState("*");
  const [process, setProcess] = useState(false);
  const [openReload, setOpenReload] = useState(false);

  const badgeReload = {
    ERROR500: {
      label: "ERRO",
      color: "red",
    },
    SUCCEEDED: {
      label: "SUCESSO",
      color: "green",
    },
    FAILED: {
      label: "ATENÇÃO",
      color: "orange",
    },
  };

  const icons = {
    ax: { icon: axIcon, iconi: axIconI, label: "After Experience" },
    bx: { icon: bxIcon, iconi: bxIconI, label: "Before Experience" },
    ex: { icon: exIcon, iconi: exIconI, label: "Employee Experience" },
    cx: { icon: cxIcon, iconi: cxIconI, label: "Customer Experience" },
    cxc: {
      icon: cxcIcon,
      iconi: cxcIconI,
      label: "Customer Experience Consulting",
    },
    slx: {
      icon: slxIcon,
      iconi: slxIconI,
      label: "Social Listening Experience",
    },
    ah: { icon: ahIcon, iconi: ahIconI, label: "Adhoc" },
  };

  useEffect(() => {
    dispatch(fetchHub());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveys.length > 0) setSurveysClone(surveys);
    // eslint-disable-next-line
  }, [surveys]);

  const fnSearch = (val) => {
    setSearchVal(val);
    setProcess(true);
    if (val.length >= 3) {
      let doSearch = setTimeout(() => {
        let surveysCloneCopy = [];
        surveys.forEach((s) => {
          const lowerText = s.label.toLowerCase();
          if (lowerText.indexOf(val) >= 0) {
            surveysCloneCopy.push(s);
          }
        });
        setSurveysClone(surveysCloneCopy);
        setProcess(false);
      }, 1000);
      return () => clearTimeout(doSearch);
    } else if (val === "") {
      setSurveysClone(surveys);
      setProcess(false);
    } else {
      setProcess(false);
    }
  };

  const fnSearchCategory = (val) => {
    setProcess(true);
    setCategory(val);
    let doCategory = setTimeout(() => {
      let surveysCloneCopy =
        val !== "*" ? surveys.filter((s) => s.type === val) : surveys;
      setSurveysClone(surveysCloneCopy);
      setProcess(false);
    }, 1000);
    return () => clearTimeout(doCategory);
  };

  return (
    <div id="home-page">
      <Card>
        <div className="inline-filters">
          <div className="options">
            <Space.Compact direction="vertical" style={{ width: "100%" }}>
              <label htmlFor="search-input">Buscar</label>
              <Input
                id="search-input"
                onChange={({ target: { value } }) =>
                  fnSearch(value.toLowerCase())
                }
                value={searchVal}
                placeholder="Ao menos 3 letras"
                autoComplete="off"
              />
            </Space.Compact>
          </div>
          <div className="options">
            <Space.Compact direction="vertical" style={{ width: "100%" }}>
              <label htmlFor="category-input">Categoria</label>
              <Select
                id="category-input"
                onChange={(value) => fnSearchCategory(value)}
                value={category}
                options={[
                  { value: "*", label: "Todas" },
                  { value: "ax", label: "(AX) After Experience" },
                  { value: "bx", label: "(BX) Before Experience" },
                  { value: "ex", label: "(EX) Employee Experience" },
                  { value: "cx", label: "(CX) Customer Experience" },
                  {
                    value: "cxc",
                    label: "(CXC) Customer Experience Consulting",
                  },
                  {
                    value: "slx",
                    label: "(SLX) Social Listening Experience",
                  },
                  { value: "ah", label: "(AH) Adhoc" },
                ]}
              />
            </Space.Compact>
          </div>
        </div>
        <Divider />
        {process && (
          <div className="in-search-brain">
            <strong>Buscando...</strong>
          </div>
        )}
        <div className="content">
          {!process &&
            !loading &&
            surveysClone.length > 0 &&
            surveysClone.map((dash, i) => {
              return (
                <Card.Grid
                  key={`dash-item-${i}`}
                  className="item"
                  style={{
                    padding: "10px",
                  }}
                >
                  <a href={dash.url}>
                    <div className="icon">
                      <img
                        src={
                          dash.status === "active"
                            ? icons[dash.type].icon
                            : icons[dash.type].iconi
                        }
                        alt="Icon"
                      />
                    </div>
                    <div className="description">
                      <div
                        className={
                          dash.status === "active"
                            ? "dash-name"
                            : "dash-name-inactive"
                        }
                      >
                        {dash.label}
                      </div>
                      <div className="dash-type">{icons[dash.type].label}</div>
                    </div>
                  </a>
                  {token.role === "admin" && (
                    <div className="more-options">
                      <Popover
                        content={
                          <Menu
                            style={{ padding: 0 }}
                            onClick={({ key }) => {
                              if (key === "open") {
                                window.open(
                                  "https://inovyo.us.qlikcloud.com/sense/app/" +
                                    dash.publish,
                                  "_blank"
                                );
                              } else if (key === "edit") {
                                window.open(
                                  "https://inovyo.us.qlikcloud.com/sense/app/" +
                                    dash.edit,
                                  "_blank"
                                );
                              } else if (key === "publish") {
                                dispatch(publishApp(dash.publish, dash.edit));
                              } else if (key === "reload") {
                                setOpenReload(!openReload);
                                dispatch(reloadApp(dash.publish));
                              }
                            }}
                            mode="inline"
                            items={[
                              {
                                label: (
                                  <>
                                    Abrir{" "}
                                    <strong>
                                      <em
                                        style={{
                                          color: "#aaa",
                                          fontSize: "10px",
                                        }}
                                      >
                                        (Produção)
                                      </em>
                                    </strong>
                                  </>
                                ),
                                key: "open",
                                icon: <ExportOutlined />,
                              },
                              {
                                label: (
                                  <>
                                    Editar{" "}
                                    <strong>
                                      <em
                                        style={{
                                          color: "#aaa",
                                          fontSize: "10px",
                                        }}
                                      >
                                        (Desenvolvimento)
                                      </em>
                                    </strong>
                                  </>
                                ),
                                key: "edit",
                                icon: <EditOutlined />,
                              },
                              {
                                label: "Publicar",
                                key: "publish",
                                icon: <SendOutlined />,
                              },
                              {
                                label: "Recarregar",
                                key: "reload",
                                icon: <ReloadOutlined />,
                              },
                            ]}
                          />
                        }
                        trigger="click"
                        placement="left"
                        style={{ padding: 0 }}
                      >
                        <MoreOutlined style={{ fontSize: 20 }} />
                      </Popover>
                    </div>
                  )}
                </Card.Grid>
              );
            })}

          {surveysClone.length <= 0 && !loading && !process && (
            <Empty
              description="Nenhuma pesquisa listada"
              image={<AppstoreOutlined />}
              style={{ margin: "auto" }}
              styles={{ image: { fontSize: "80px", color: "#aaa" } }}
            />
          )}
        </div>
      </Card>
      <Modal
        open={openReload}
        cancelText="Cancelar"
        zIndex={10000}
        closable={false}
        onCancel={() => {
          setOpenReload(!openReload);
          dispatch(setReloadRedux(false));
          dispatch(setQlikRedux(null));
        }}
        onOk={() => {
          setOpenReload(!openReload);
          dispatch(setReloadRedux(false));
          dispatch(setQlikRedux(null));
        }}
        title={
          reload ? (
            "Processando..."
          ) : (
            <>
              Recarga de dados{" "}
              {qlik !== null && (
                <Badge
                  count={badgeReload[qlik.status].label}
                  color={badgeReload[qlik.status].color}
                />
              )}
            </>
          )
        }
      >
        <div
          style={{
            height: "400px",
            overflow: "hidden auto",
            fontSize: "12px",
            color: "#666",
          }}
        >
          {qlik !== null ? (
            <Interweave content={qlik?.log} />
          ) : (
            "Aguarde! Esta ação pode levar segundos ou alguns poucos minutos..."
          )}
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
