import { toast } from "react-toastify";
import { setCookie } from "../../utils/Cookie";

//redux
import {
  doLoginAPI,
  handleEmailAPI,
  handleCodeAPI,
  saveMyPassAPI,
  doLogoutAPI,
} from "./auth.service";
import { setStepRedux, setStatusRedux } from "./auth.slice";
import { setLoadingRedux } from "../application/app.slice";

//utils
import { setSession, deleteSession } from "../../utils/Session";
import { base64encode } from "../../utils/Crypt";

export const doLogin = (dataLogin) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    doLoginAPI(dataLogin)
      .then((jsonAPI) => {
        toast.success("Acesso autorizado", { autoClose: 1000 });
        setCookie("TOKEN", jsonAPI.jwtToken, 1);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch(() => {
        toast.error("Usuário não encontrado!");
        dispatch(setLoadingRedux(false));
      });
  };
};

export const doLogout = () => {
  return () => {
    doLogoutAPI()
      .then(() => {})
      .catch(() => {});
  };
};

export const handleEmail = (email) => {
  return (dispatch) => {
    handleEmailAPI(email)
      .then(({ data }) => {
        const { status } = data;
        dispatch(setStatusRedux(status));

        if (status === "success") {
          dispatch(setStepRedux(2));
          toast.success("Código de verificação enviado, confira seu e-mail.");
        } else {
          toast.error(
            "Falha ao solicitar o resgate de senha, tente novamente!"
          );
        }
      })
      .catch((error) => {
        if (error?.response && error.response.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Ocorreu um erro, tente novamente mais tarde!");
        }
      })
      .finally(() => {
        toast.dismiss("email");
      });
  };
};

export const handleCode = (dataRecover) => {
  return () => {
    handleCodeAPI(dataRecover)
      .then(({ data }) => {
        const { status } = data;
        if (status === "success") {
          toast.success("Código verificado com sucesso!");
          setSession("RECOVER", base64encode(dataRecover, true));
          setTimeout(() => {
            window.location.href = "/new-pass";
          }, 2000);
        } else {
          toast.error("Código inválido, tente novamente.");
        }
      })
      .catch(() => {
        toast.error("Ocorreu um erro, tente novamente mais tarde!");
      })
      .finally(() => {
        toast.dismiss("codigo");
      });
  };
};

export const saveMyPass = (data) => {
  return () => {
    saveMyPassAPI(data)
      .then(() => {
        toast.success("Senha alterada com sucesso, realize seu login!");
        deleteSession("RECOVER");
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      })
      .catch((error) => {
        if (error?.response && error.response.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Ocorreu um erro, tente novamente mais tarde!");
        }
      })
      .finally(() => {
        toast.dismiss("pass");
      });
  };
};
