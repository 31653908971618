import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "surveys",
  initialState: {
    listRedux: null,
    tbLoadingRedux: false, //loading das tabelas
  },
  reducers: {
    setListRedux(state, { payload }) {
      state.listRedux = payload;
    },
    setTbLoadingRedux(state, { payload }) {
      state.tbLoadingRedux = payload;
    },
  },
});

export const { setListRedux, setTbLoadingRedux } = slice.actions;

export const listRedux = (state) => state.surveys.listRedux;
export const tbLoadingRedux = (state) => state.surveys.tbLoadingRedux;

export default slice.reducer;
