import React, { useContext } from "react";
import moment from "moment";

//provider
import { Context } from "../../providers/AuthContext";

//styles
import "../../assets/styles/components/app/footer-app.css";

//images
import LogoBrain from "../../assets/images/brain-inovyo.svg";

const FooterApp = () => {
  const { authenticated } = useContext(Context);

  return (
    <>
      {authenticated && (
        <footer id="footer-page">
          <img src={LogoBrain} alt="LogoBrain" /> <br />
          <sup>&copy;</sup> Inovyo | Experience Management. <br />
          <span>
            Todos os direitos reservados.
            <br />
            {moment().format("YYYY")}
          </span>
        </footer>
      )}
    </>
  );
};

export default FooterApp;
