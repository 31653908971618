import { generalRoutes } from "../modules/general";
import { authRoutes } from "../modules/auth";
import { baseRoutes } from "../modules/base";
import { userRoutes } from "../modules/user";
import { appsRoutes } from "../modules/apps";
import { spacesRoutes } from "../modules/spaces";
import { settingsRoutes } from "../modules/settings";
import { surveyRoutes } from "../modules/surveys";

import Page404 from "../../pages/general/page404";

export const listRoutes = () => {
  let routesArray = [];
  let routesArrayNew = routesArray.concat(
    generalRoutes(),
    authRoutes(),
    baseRoutes(),
    userRoutes(),
    appsRoutes(),
    spacesRoutes(),
    settingsRoutes(),
    surveyRoutes()
  );

  //page do not found
  routesArrayNew.push({ path: "*", page: Page404 });

  return routesArrayNew;
};

export const activeRoute = () => {
  let route = window.location.pathname;
  let routeData = {};

  listRoutes().forEach((element) => {
    if (element.path === route || route.indexOf(element.name) >= 0) {
      routeData = element;
      return;
    }
  });

  return routeData;
};
