import { toast } from "react-toastify";

//redux
import { getListSurveysAPI } from "./surveys.service";
import { setListRedux } from "./surveys.slice";
import { setLoadingRedux } from "../application/app.slice";

export const getListSurveys = (user) => {
  return (dispatch) => {
    const surveyTypes = {
      AX: "After Experience",
      BX: "Before Experience",
      EX: "Employee Experience",
      CX: "Customer Experience",
      CXC: "Customer Experience Consulting",
      SLX: "Social Listening Experience",
      AH: "Adhoc",
    };

    dispatch(setLoadingRedux(true));
    getListSurveysAPI(user)
      .then(({ data }) => {
        const row = data.data.map((item) => {
          item.type = surveyTypes[item.type] || surveyTypes.CX;
          return item;
        });
        dispatch(setListRedux(row));
      })
      .catch(() => {
        toast.error("Falha ao carregar pesquisas!");
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};
