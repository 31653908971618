import LoginPage from "../../pages/auth/login";
import LogoutPage from "../../pages/auth/logout";
import RecoverPassPage from "../../pages/auth/recover-pass";
import NewPassPage from "../../pages/auth/new-pass";

//AUTH
export const authRoutes = () => {
  return [
    { path: "/login", page: LoginPage },
    { path: "/logout", page: LogoutPage },
    { path: "/recover-pass", page: RecoverPassPage },
    { path: "/new-pass", page: NewPassPage },
  ];
};
