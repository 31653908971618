import api, { headers } from "../../services/api";

export const fetchBasesAPI = async (userId) => {
  return api.get(`bases/${userId}`, { headers });
};

export const getSurveysToBaseAPI = async (userId) => {
  return api.get(`pesquisas/${userId}`, { headers });
};

export const sendBaseAPI = async (userId, baseData) => {
  let formData = new FormData();
  formData.append("file", baseData.file);
  return api.post(`enviar-base/${userId}`, formData, { headers });
};

export const saveBaseAPI = async (data) => {
  return api.post(`salvar-base`, data, { headers });
};

export const downloadBaseExcelAPI = async (data) => {
  return api.post(`download-base`, data, { headers });
};

export const deleteBaseAPI = async (data) => {
  return api.delete(
    `deletar-base/${data.key}?preffix=${data.preffix}&filename=${data.filename}`,
    {
      headers,
    }
  );
};
