import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Table } from "antd";

//providers
import { Context } from "../../providers/AuthContext";

//redux
import { listRedux, tbLoadingRedux } from "../../redux/surveys/surveys.slice";
import { getListSurveys } from "../../redux/surveys/surveys.action";

const SurveysPage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  //states
  const [resultsOnPage, setResultsOnPage] = useState(10);

  //redux state
  const tableLoading = useSelector(tbLoadingRedux);
  const list = useSelector(listRedux);

  useEffect(() => {
    dispatch(getListSurveys(token.user));
    // eslint-disable-next-line
  }, []);

  const tableChanges = (e) => {
    if (e.pageSize !== resultsOnPage) {
      setResultsOnPage(e.pageSize);
    }
  };

  return (
    <Card>
      <div id="spaces-page">
        <Title level={4}>Pesquisas</Title>
        <Table
          responsive={true}
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
              sorter: (a, b) => a.name - b.name,
            },
            {
              title: "Tipo",
              dataIndex: "type",
            },
          ]}
          dataSource={list}
          pagination={{
            pageSize: resultsOnPage,
            showSizeChanger: true,
            size: "small",
          }}
          onChange={(e) => tableChanges(e)}
          scroll={{ y: 380 }}
          loading={tableLoading}
        />
      </div>
    </Card>
  );
};

export default SurveysPage;
