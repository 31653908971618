import { toast } from "react-toastify";

//redux
import {
  setBasesRedux,
  setTbLoadingRedux,
  setSurveysRedux,
  setAllSurveysRedux,
  setResultUploadRedux,
  setSuccessRedux,
} from "./bases.slice";
import {
  fetchBasesAPI,
  getSurveysToBaseAPI,
  sendBaseAPI,
  saveBaseAPI,
  downloadBaseExcelAPI,
  deleteBaseAPI,
} from "./bases.service";
import { setLoadingRedux } from "../application/app.slice";

export const fetchBases = (user) => {
  return (dispatch) => {
    dispatch(setTbLoadingRedux(true));
    fetchBasesAPI(user)
      .then(({ data }) => {
        dispatch(setBasesRedux(data.files));
      })
      .catch(() => {
        toast.error("Falha ao carregar dados das bases. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setTbLoadingRedux(false));
        }, 1000);
      });
  };
};

export const getSurveysToBase = (user) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getSurveysToBaseAPI(user)
      .then(({ data }) => {
        const surveys = data.data
          .filter((v) => v.status !== "I")
          .map((v) => {
            return {
              value: v.key,
              label: `${v.client.toUpperCase()} - ${v.name}`,
            };
          });
        dispatch(setAllSurveysRedux(data.data));
        dispatch(setSurveysRedux(surveys));
      })
      .catch(() => {
        toast.error("Falha ao carregar dados prévios. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingRedux(false));
        }, 1000);
      });
  };
};

export const sendToBase = (user, baseData) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    sendBaseAPI(user, baseData)
      .then((data) => {
        dispatch(setResultUploadRedux(data.data));
        toast.success("Upload concluído");
      })
      .catch(() => {
        toast.error("Falha ao subir base. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingRedux(false));
        }, 1000);
      });
  };
};

export const saveBase = (data) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    saveBaseAPI(data)
      .then(() => {
        toast.success("Base salva com sucesso!");
        dispatch(setSuccessRedux(true));
      })
      .catch(() => {
        toast.error("Falha ao salvar base. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingRedux(false));
        }, 1000);
      });
  };
};

export const downloadBaseExcel = (info) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    downloadBaseExcelAPI(info)
      .then(({ data }) => {
        toast.loading("Concluído! Iniciando o download..", {
          toastId: "download",
        });

        setTimeout(() => {
          const { url } = data;
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", info.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          toast.done("download");
        }, 3000);
      })
      .catch(() => {
        toast.error("Por favor, tente novamente. Ocorreu um erro.");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingRedux(false));
        }, 1000);
      });
  };
};

export const deleteBase = (info) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    deleteBaseAPI(info)
      .then(() => {
        toast.success("Base excluída com sucesso!");
        setTimeout(() => {
          window.location.href = "/bases";
        }, 1000);
      })
      .catch(() => {
        toast.error("Por favor, tente novamente. Ocorreu um erro.");
        dispatch(setLoadingRedux(false));
      });
  };
};
