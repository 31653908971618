import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "auth",
  initialState: {
    step: 1,
    status: "",
  },
  reducers: {
    setStepRedux(state, { payload }) {
      state.step = payload;
    },
    setStatusRedux(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setStepRedux, setStatusRedux } = slice.actions;

export const stepRedux = (state) => state.auth.step;
export const statusRedux = (state) => state.auth.status;

export default slice.reducer;
