import React, { useState } from "react";
import { Card, Typography, Checkbox, Button, Input, Select, Form } from "antd";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  ApiOutlined,
  IdcardOutlined,
  UserOutlined,
  MailOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import _v from "validator";

//redux
import { createUser } from "../../redux/users/users.actions";

const NewUserPage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const [dataForm, setDataForm] = useState({
    user: "",
    company: "",
    email: "",
    name: "",
    role: "",
    oauth_only: false,
  });

  const handleSelect = (value) => {
    setDataForm({ ...dataForm, role: value });
  };

  const handleCheck = (value) => {
    setDataForm({ ...dataForm, oauth_only: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { user, company, name, email, role } = dataForm;
    let error = "";

    //Valida user
    if (error === "") {
      if (_v.isEmpty(user)) {
        error = "Nome da conta está vazio";
      } else if (!_v.isLength(user, { min: 3, max: 60 })) {
        error = "Nome da conta deve conter entre 3 e 60 caracteres";
      } else if (!/^[a-zA-Z0-9]*$/g.test(user)) {
        error =
          "Nome de conta deve conter apenas letras e/ou números, sem espaço";
      }
    }

    //valida emrpesa
    if (error === "") {
      if (_v.isEmpty(company)) {
        error = "Empresa está vazio";
      } else if (!_v.isLength(company, { min: 3, max: 60 })) {
        error = "Empresa deve conter entre 3 e 60 caracteres";
      }
      /* else if (!/^[a-zA-Z0-9\s]*$/g.test(company)) {
        error = "Empresa não deve conter caracteres especiais";
      } */
    }

    //valida contato
    if (error === "") {
      if (_v.isEmpty(name)) {
        error = "Contato está vazio";
      } else if (!_v.isLength(name, { min: 2, max: 80 })) {
        error = "Contato deve conter entre 2 e 80 caracteres";
      }
      /*  else if (!/^[a-zA-Z0-9\s]*$/g.test(name)) {
        error = "Contato não deve conter caracteres especiais";
      } */
    }

    //valida email
    if (error === "") {
      if (_v.isEmpty(email)) {
        error = "Email está vazio";
      } else if (!_v.isLength(email, { min: 6, max: 120 })) {
        error = "Email deve conter entre 6 e 120 caracteres";
      } else if (!_v.isEmail(email)) {
        error = "Email inválido";
      }
    }

    //valida role
    if (error === "") {
      if (_v.isEmpty(role)) {
        error = "Defina o nível de permissão";
      }
    }

    if (error !== "") {
      toast.warning(error);
    } else {
      dispatch(createUser(dataForm));
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    switch (id) {
      case "user":
        setDataForm({ ...dataForm, user: value });
        break;
      case "company":
        setDataForm({ ...dataForm, company: value });
        break;
      case "email":
        setDataForm({ ...dataForm, email: value });
        break;
      case "name":
        setDataForm({ ...dataForm, name: value });
        break;
      default:
        break;
    }
  };

  return (
    <Card>
      <div id="new-user-page">
        <Button
          href="/bases"
          type="default"
          style={{
            position: "absolute",
            zIndex: 10,
            top: "20px",
            left: "20px",
          }}
          icon={<ArrowLeftOutlined />}
        >
          Usuários
        </Button>
        <Title level={4} style={{ paddingLeft: "120px" }}>
          Novo Usuário
        </Title>
        <Form layout="vertical">
          <Form.Item
            label="Nome da conta"
            name="user"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome da conta",
              },
            ]}
          >
            <Input
              onChange={(e) => handleChange(e)}
              prefix={<ApiOutlined />}
              name="user"
              id="user"
              value={dataForm.user}
            />
          </Form.Item>
          <Form.Item
            label="Empresa"
            name="company"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome da empresa",
              },
            ]}
          >
            <Input
              onChange={(e) => handleChange(e)}
              prefix={<IdcardOutlined />}
              name="company"
              id="company"
              autoComplete="on"
              value={dataForm.company}
            />
          </Form.Item>
          <Form.Item
            label="Contato"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome do contato",
              },
            ]}
          >
            <Input
              onChange={(e) => handleChange(e)}
              prefix={<UserOutlined />}
              name="name"
              id="name"
              autoComplete="on"
              value={dataForm.name}
            />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            autoComplete="on"
            rules={[{ required: true, message: "Por favor, insira um e-mail" }]}
          >
            <Input
              onChange={(e) => handleChange(e)}
              prefix={<MailOutlined />}
              name="email"
              id="email"
              autoComplete="on"
              value={dataForm.email}
            />
          </Form.Item>
          <Form.Item
            label="Permissão"
            name="role"
            rules={[
              {
                required: true,
                message: "Por favor, selecione uma permissão",
              },
            ]}
          >
            <Select
              id="role"
              name="role"
              placeholder="Selecione uma permissão"
              onSelect={(key) => handleSelect(key)}
              options={[
                {
                  value: "admin",
                  label: "Administrador",
                },
                {
                  value: "customersuccess",
                  label: "Customer Success",
                },
                {
                  value: "sale",
                  label: "Comercial",
                },
                {
                  value: "client",
                  label: "Cliente",
                },
                {
                  value: "demo",
                  label: "Demo",
                },
                {
                  value: "ftp",
                  label: "FTP",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="oauth_only">
            <Checkbox
              style={{ marginTop: 8 }}
              name="oauth_only"
              onChange={({ target: { checked } }) => handleCheck(checked)}
              checked={dataForm.oauth_only}
            >
              Gerenciado pelo cliente
            </Checkbox>
          </Form.Item>
          <Form.Item style={{ float: "right" }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Criar usuário
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
};

export default NewUserPage;
