import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Typography,
  Button,
  Steps,
  Form,
  Select,
  Checkbox,
  Upload,
  Col,
  Row,
  Divider,
  Result,
} from "antd";
import {
  ArrowLeftOutlined,
  InboxOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

//redux
import {
  getSurveysToBase,
  sendToBase,
  saveBase,
} from "../../redux/bases/bases.actions";
import {
  surveysRedux,
  allSurveysRedux,
  resultUploadRedux,
  successRedux,
  setSuccessRedux,
} from "../../redux/bases/bases.slice";

//provide
import { Context } from "../../providers/AuthContext";

//styles
import "../../assets/styles/bases/send-bases.css";

const SendBasePage = () => {
  const { Title } = Typography;
  const { token } = useContext(Context);
  const dispatch = useDispatch();
  const { Dragger } = Upload;

  const defaultFields = [
    { key: "df-0", value: "null", label: "Ignorar campo" },
    { key: "df-1", value: "name", label: "Nome" },
    { key: "df-2", value: "email", label: "E-mail" },
    { key: "df-3", value: "phone", label: "Telefone" },
  ];

  //states
  const [current, setCurrent] = useState(0);
  const [fieldsOptionCopy, setFieldsOptionCopy] = useState(null);
  const [fieldsSelecteds, setFieldsSelecteds] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [baseData, setBaseData] = useState({
    survey: "Selecione uma pesquisa",
    surveyComplete: null,
    file: null,
    isNotSurvey: false,
    headerChecked: true,
    optinChecked: true,
    fieldsOption: null,
    rowsFile: 0,
    fieldsFile: null,
  });

  //redux state
  const surveys = useSelector(surveysRedux);
  const allSurveys = useSelector(allSurveysRedux);
  const resultUpload = useSelector(resultUploadRedux);
  const success = useSelector(successRedux);

  useEffect(() => {
    dispatch(getSurveysToBase(token.user));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (resultUpload !== null) {
      setBaseData({
        ...baseData,
        fieldsFile: resultUpload.fields,
        rowsFile: resultUpload.rows,
      });

      const arr = new Array(resultUpload.fields.length).fill({
        key: "df-0",
        value: "null",
        label: "Ignorar campo",
      });
      setFieldsSelecteds(arr);

      setCurrent(2);
    }
    // eslint-disable-next-line
  }, [resultUpload]);

  useEffect(() => {
    if (success) {
      setCurrent(3);
    }
    // eslint-disable-next-line
  }, [success]);

  const nextUpload = (e) => {
    e.preventDefault();

    if (baseData.isNotSurvey) {
      setCurrent(1);
    } else {
      if (baseData.survey === "Selecione uma pesquisa") {
        toast.warning("Você precisa selecionar um ponto de contato/pesquisa.");
      } else {
        setCurrent(1);
      }
    }
  };

  const sendBase = async () => {
    dispatch(sendToBase(token.user, baseData));
  };

  const setSurvey = (val) => {
    const surveyData = allSurveys.filter((s) => s.key === val).shift();

    let fieldsOption =
      surveyData?.customfield && surveyData.customfield !== null
        ? [].concat(defaultFields, surveyData.customfield)
        : defaultFields;

    setFieldsOptionCopy(fieldsOption);

    // reseta dados caso mude a pesquisa
    if (typeof baseData.survey === "number" && baseData.survey !== val) {
      setBaseData({
        ...baseData,
        survey: val,
        surveyComplete: surveyData,
        fieldsOption,
        file: null,
        headerChecked: true,
        optinChecked: true,
      });
    }
    //seta dados da pesquisa
    else {
      const surveyData = allSurveys.filter((s) => s.key === val);
      setBaseData({
        ...baseData,
        survey: val,
        surveyComplete: surveyData,
        fieldsOption,
      });
    }
  };

  const changeFields = (field, position) => {
    let objValue = [...fieldsSelecteds];
    let objCopy = [];

    let copySelectedValues = { ...selectedValues };
    copySelectedValues[field.value] = baseData.fieldsFile[position];
    setSelectedValues(copySelectedValues);

    if (["E-mail", "Nome", "Telefone"].includes(field.label)) {
      objValue[position] = defaultFields.filter(
        (df) => df.value === field.value
      )[0];
    } else {
      objValue[position] = field;
    }
    setFieldsSelecteds(objValue);

    baseData.fieldsOption.forEach((f) => {
      let finded = false;

      if (f.value !== "null") {
        objValue.forEach((o) => {
          if (o.label === f.label) {
            finded = true;
          }
        });
      }

      if (!finded) {
        objCopy.push(f);
      }
    });

    setFieldsOptionCopy(objCopy);
  };

  const completeAndSaveBase = (e) => {
    e.preventDefault();

    let dataFields = {
      header: baseData.headerChecked,
    };

    for (let index in selectedValues) {
      if (selectedValues[index] !== "null") {
        dataFields[index] = selectedValues[index];
      }
    }

    dispatch(
      saveBase({
        filename: baseData.file.name,
        surveyid: baseData.survey,
        rows: baseData.rowsFile,
        filefields: dataFields,
        user: token.id,
      })
    );
  };

  const props = {
    name: "file",
    accept:
      ".csv, .xlsx, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload(file) {
      const type = file.name.split(".").pop();
      const size = file.size;
      if (size >= 10000000) {
        toast.error("Arquivo maior que 10MB");
        return false;
      }
      if (!["csv", "xlsx", "xls"].includes(type.toLowerCase())) {
        toast.error("Formato de arquivo não permitido");
        return false;
      }

      setBaseData({ ...baseData, file });
      return false;
    },
    onRemove() {
      setBaseData({ ...baseData, file: null });
    },
  };

  const BackButton = ({ current }) => {
    return (
      <Button
        type="default"
        onClick={() => setCurrent(current)}
        style={{ float: "left", marginTop: 24 }}
      >
        Voltar
      </Button>
    );
  };

  return (
    <Card>
      <div id="send-bases-page">
        <Button
          href="/bases"
          type="default"
          style={{
            position: "absolute",
            zIndex: 10,
            top: "20px",
            left: "20px",
          }}
          icon={<ArrowLeftOutlined />}
        >
          Bases
        </Button>
        <Title level={4} style={{ paddingLeft: "100px" }}>
          Envio de Base
        </Title>
        <Steps
          current={current}
          items={[
            {
              title: "Pesquisa",
              key: 0,
            },
            {
              title: "Upload",
              key: 1,
            },
            {
              title: "Campos",
              key: 2,
            },
            {
              title: "Fim",
              key: 3,
            },
          ]}
        />
        <div className="steps-content" style={{ margin: 24, minHeight: 200 }}>
          {current === 0 && (
            <Form layout="vertical">
              <Form.Item
                label="Pesquisa"
                style={{
                  display: "inline-block",
                  width: "100%",
                  marginBottom: 6,
                }}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder="Selecione uma pesquisa"
                  disabled={baseData.isNotSurvey}
                  defaultValue={baseData.survey}
                  onSelect={(val) => setSurvey(val)}
                  options={surveys}
                />
                <Checkbox
                  style={{ marginTop: 8 }}
                  checked={baseData.isNotSurvey}
                  onChange={() => {
                    let baseDataCopy = { ...baseData };
                    baseDataCopy.isNotSurvey = !baseDataCopy.isNotSurvey;
                    baseDataCopy.survey = baseDataCopy.isNotSurvey
                      ? "Sem pesquisa"
                      : "Selecione uma pesquisa";
                    setBaseData(baseDataCopy);
                  }}
                >
                  Não encontrei o ponto de contato/pesquisa
                </Checkbox>
              </Form.Item>
              <Form.Item style={{ float: "right", marginTop: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={(e) => nextUpload(e)}
                >
                  Próximo
                </Button>
              </Form.Item>
            </Form>
          )}

          {current === 1 && (
            <>
              {baseData.file === null ? (
                <>
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Clique aqui ou arraste o arquivo para fazer upload
                    </p>
                    <p className="ant-upload-hint">
                      Upload de um único arquivo, no formato XLSX, XLS ou CSV
                      com no máximo 10MB.
                    </p>
                  </Dragger>
                  <BackButton current={0} />
                </>
              ) : (
                <>
                  <div className="delete-box">
                    {baseData.file.name}
                    <DeleteOutlined
                      onClick={() => {
                        setBaseData({
                          ...baseData,
                          optinChecked: true,
                          headerChecked: true,
                          file: null,
                        });
                      }}
                    />
                  </div>
                  <Title level={4} style={{ marginTop: 16 }}>
                    Opções
                  </Title>
                  <Form>
                    <Form.Item style={{ marginBottom: 4 }}>
                      <Checkbox
                        checked={baseData.headerChecked}
                        onChange={() => {
                          setBaseData({
                            ...baseData,
                            headerChecked: !baseData.headerChecked,
                          });
                        }}
                      >
                        O arquivo inclui uma linha de cabeçalho
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={baseData.optinChecked}
                        onChange={() => {
                          setBaseData({
                            ...baseData,
                            optinChecked: !baseData.optinChecked,
                          });
                        }}
                      >
                        Tenho permissão para enviar mensagens para os contatos
                        desta base
                        <hr />
                        <small>
                          Confirmo que tenho permissão explícita para enviar
                          mensagens a todos os contatos enviados à Inovyo, de
                          acordo com a legislação local, estadual, federal e
                          internacional.
                        </small>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <BackButton current={0} />
                      {baseData.file !== null && (
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={() => sendBase()}
                          disabled={baseData.file === null}
                          style={{ float: "right", marginTop: 24 }}
                        >
                          Próximo
                        </Button>
                      )}
                    </Form.Item>
                  </Form>
                </>
              )}
            </>
          )}

          {current === 2 && (
            <>
              <Form>
                <Row>
                  <Col span={24}>
                    <p>
                      Agora, combine as colunas do arquivo carregado com o campo
                      de contato da pesquisa.
                    </p>
                  </Col>
                </Row>
                <Divider />
                <Row style={{ marginBottom: 6 }}>
                  <Col span={12}>
                    <p style={{ fontWeight: "bold" }}>Cabeçalho do arquivo</p>
                  </Col>
                  <Col span={12}>
                    <p style={{ fontWeight: "bold" }}>Campos da pesquisa</p>
                  </Col>
                </Row>
                {baseData.fieldsFile.map((item, index) => (
                  <Row style={{ marginBottom: 6 }} key={`line-${index}`}>
                    <Col span={12}>
                      <p>
                        {baseData.headerChecked ? item : `Coluna ${index + 1}`}
                      </p>
                    </Col>
                    <Col span={12}>
                      <Select
                        placeholder="Selecione um campo"
                        labelInValue
                        style={{ width: "100%" }}
                        id="select-field"
                        defaultValue={
                          fieldsSelecteds !== null &&
                          typeof fieldsSelecteds[index] === "object"
                            ? fieldsSelecteds[index].value
                            : "null"
                        }
                        onSelect={(val) => changeFields(val, index)}
                        options={
                          fieldsOptionCopy !== null ? fieldsOptionCopy : []
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={(e) => completeAndSaveBase(e)}
                    style={{ float: "right", marginTop: 24 }}
                  >
                    Concluir
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}

          {current === 3 && (
            <>
              <Result
                status="success"
                title="Base recebida!"
                extra={[
                  <Button
                    type="primary"
                    key="again"
                    onClick={() => {
                      setBaseData({
                        survey: "Selecione uma pesquisa",
                        surveyComplete: null,
                        file: null,
                        isNotSurvey: false,
                        headerChecked: true,
                        optinChecked: true,
                        fieldsOption: null,
                        rowsFile: 0,
                        fieldsFile: null,
                      });
                      setSelectedValues({});
                      setCurrent(0);
                      dispatch(setSuccessRedux(false));
                    }}
                  >
                    Enviar nova base
                  </Button>,
                  <Button
                    type="secondary"
                    key="bases"
                    onClick={() => {
                      window.location.href = "/bases";
                    }}
                  >
                    Ir para Histórico
                  </Button>,
                ]}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SendBasePage;
