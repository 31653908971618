import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";

//utils
import { clearStorages } from "../../utils/Storage";
import { clearSessions } from "../../utils/Session";
import { clearCookies } from "../../utils/Cookie";

//redux
import { doLogout } from "../../redux/auth/auth.actions";

//styles
import "../../assets/styles/auth/logout.css";

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    dispatch(doLogout());

    setTimeout(() => {
      clearStorages();
      clearSessions();
      clearCookies();
      window.location.href = "/login";
      return;
    }, 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="logout-page">
      <div>
        Saindo...
        <br />
        <ReactLoading type="bubbles" color="#000000" />
      </div>
    </div>
  );
};

export default LogoutPage;
