import React, { useContext, useState } from "react";
import { Menu, Badge, Drawer } from "antd";
import {
  SettingOutlined,
  BellOutlined,
  BellFilled,
  MenuOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
//import { toast } from "react-toastify";

//styles
import "../../assets/styles/components/app/header-app.css";

//images
import LogoBrain from "../../assets/images/brain-inovyo.svg";

//provider
import { Context } from "../../providers/AuthContext";

//redux
import {
  notificationsRedux,
  setOpenNotificationsRedux,
} from "../../redux/application/app.slice";

const HeaderApp = () => {
  const dispatch = useDispatch();
  const route = window.location.pathname;
  const { authenticated, token } = useContext(Context);

  const [openMenu, setOpenMenu] = useState(false);
  //const [openMagic, setOpenMagic] = useState(false);
  const notifications = useSelector(notificationsRedux);

  //menu horizontal
  const MenuButtons = ({ display }) => (
    <div className={`menu-small-${display}`}>
      {token.role !== "ftp" && (
        <a href="/" className={route === "/" ? "selected" : ""}>
          Dashboards
        </a>
      )}

      {["admin", "customersuccess", "sale"].includes(token.role) && (
        <a
          href="/users"
          className={["/users", "/new-user"].includes(route) ? "selected" : ""}
        >
          Usuários
        </a>
      )}

      {token.role !== "demo" && (
        <>
          <a
            href="/bases"
            className={
              ["/bases", "/send-bases"].includes(route) ? "selected" : ""
            }
          >
            Bases
          </a>
          <a href="/surveys" className={route === "/surveys" ? "selected" : ""}>
            Pesquisas
          </a>
        </>
      )}

      {token.role === "admin" && (
        <>
          <a href="/apps" className={route === "/apps" ? "selected" : ""}>
            Apps
          </a>
          <a href="/spaces" className={route === "/spaces" ? "selected" : ""}>
            Espaços
          </a>
          <a
            href="https://inovyo.us.qlikcloud.com/"
            target="_blank"
            rel="noreferrer"
          >
            Qlik
          </a>
        </>
      )}

      {display === "vertical" && (
        <>
          <a href="/account" className={route === "/account" ? "selected" : ""}>
            Configurações
          </a>
          <a href="/logout">Sair</a>
        </>
      )}
    </div>
  );

  /* const liAction = (item) => {
    if (item.key === "survey") {
      toast.info("Recurso em desenvolvimento");
    } else {
      window.location.href = item.url;
    }
  }; */

  return (
    <>
      {authenticated && (
        <header id="header-inovyo">
          <div className="logo">
            <a href="/" title="Home">
              <img src={LogoBrain} alt="LogoBrain" />
            </a>
          </div>

          {/* {["admin", "customersuccess"].includes(token.role) && (
            <div className="magic-button">
              <Button type="default" onClick={() => setOpenMagic(!openMagic)}>
                + Criar
              </Button>
            </div>
          )} */}

          <MenuButtons display="horizontal" />
          <div className="options">
            <div className="notifcation-inovyo">
              <Badge
                dot={notifications.length > 0}
                onClick={() => dispatch(setOpenNotificationsRedux())}
              >
                {notifications.length > 0 ? (
                  <BellFilled style={{ fontSize: "21px" }} />
                ) : (
                  <BellOutlined style={{ fontSize: "21px" }} />
                )}
              </Badge>
            </div>
            <div className="menu-inovyo" onClick={() => setOpenMenu(!openMenu)}>
              <MenuOutlined />
            </div>
          </div>
          <div className="options2">
            <Menu
              onClick={({ key }) => {
                window.location.href = "/" + key;
                return;
              }}
              style={{ height: "60px" }}
              mode="horizontal"
              items={[
                {
                  label: "Conta",
                  key: "SubMenu",
                  icon: <SettingOutlined />,
                  children: [
                    {
                      label: "Configurações",
                      key: "account",
                    },
                    {
                      label: "Sair",
                      key: "logout",
                    },
                  ],
                },
              ]}
            />
          </div>
          <Drawer
            title="Inovyo"
            placement="right"
            closable={true}
            open={openMenu}
            onClose={() => setOpenMenu(!openMenu)}
            width={320}
            id="menu-small-drawer"
          >
            <MenuButtons display="vertical" />
          </Drawer>
          {/* <Modal
            open={openMagic}
            footer={null}
            closable
            onCancel={() => setOpenMagic(!openMagic)}
            width={300}
            style={{ padding: 0 }}
            styles={{
              body: { padding: 0, margin: 0 },
              content: { padding: 0, margin: 0 },
            }}
          >
            <List
              size="small"
              bordered
              dataSource={[
                { label: "Novo Usuário", url: "/new-user", key: "user" },
                { label: "Enviar Base", url: "/send-bases", key: "base" },
                { label: "Nova Pesquisa", url: "/new-survey", key: "survey" },
              ]}
              id="magic-menu"
              header={
                <>
                  <img src={LogoBrain} alt="logobrain" width="20px" /> Inovyo
                </>
              }
              renderItem={(item) => (
                <List.Item onClick={() => liAction(item)}>
                  {item.label}
                </List.Item>
              )}
            />
          </Modal> */}
        </header>
      )}
    </>
  );
};

export default HeaderApp;
